<template>
  <div class="flex flex-col font-montserrat">
    <div class="flex flex-col bg-white">
      <Header :currentRoutes="getCurrentRoute()" />
    </div>
    <div
      class="flex flex-col mx-auto w-full lg:w-700px xxl:w-1100px h-screen overflow-y-auto"
    >
      <div class="flex flex-col gap-1 py-4 md:gap-0 md:flex-row md:py-6">
        <Search
          :onInput="onSearch"
          :onClear="onClearSearch"
          :value="searchValue"
          placeholder="Cari pesan"
          class="mr-4 w-full md:w-2/3"
        />
        <Select
          :dataSelect="filterData"
          :onOpenSelect="onOpenFilterSelect"
          :onCloseSelect="onCloseFilterSelect"
          :onChooseSelect="onSelectFilter"
          :value="filterValue"
          :isOpenMenu="filterSelect"
          class="w-full md:w-1/3 filter_data"
          :isDisabled="!isNotificationsExist"
        />
      </div>
      <h1 v-if="isLoading">Loading.......</h1>

      <!-- Handle Error Data Not Found, Connection, System -->
      <div
        v-if="isError && !isLoading"
        class="w-full h-screen pb-64 justify-center items-center flex flex-col mx-auto py-20 text-12px xxl:text-14px overflow-hidden"
      >
        <ErrorMessage
          :errorType="isError ? errorCause : 'notfound'"
          :onTryAgain="isError && onReload"
        />
      </div>

      <!-- Is No Error -->
      <div v-if="!isLoading && !isError">
        <div
          v-if="!isNotificationsExist && searchValue"
          class="w-full h-screen pb-64 bg-white justify-center items-center flex flex-col mx-auto py-20 text-12px xxl:text-14px overflow-hidden"
        >
          <img
            src="@/app/ui/assets/svg/data-not-found.svg"
            class="w-32 mt-8 mx-auto"
            alt="not_found"
          />
          <div class="text-gray-lp-300 py-2 font-semibold">
            Tidak ada hasil yang ditemukan
          </div>
          <div class="text-12px xxl:text-14px text-gray-lp-600 font-normal">
            Apa yang Anda cari tidak ditemukan atau tidak ada.
          </div>
        </div>
        <div
          v-if="!isNotificationsExist && !searchValue"
          class="w-full h-screen pb-64 bg-white justify-center items-center flex flex-col mx-auto py-20 text-12px xxl:text-14px overflow-hidden"
        >
          <img
            src="@/app/ui/assets/svg/no-notif.svg"
            class="w-32 mt-8 mx-auto"
            alt="no_notif"
          />
          <div
            class="text-12px xxl:text-14px text-gray-lp-800 font-normal py-2"
          >
            Belum ada pesan yang dapat ditampilkan
          </div>
        </div>
      </div>
      <div
        v-if="isNotificationsExist"
        class="flex flex-col overflow-auto h-screen pb-64 w-full"
      >
        <div v-if="mainNotifications.length !== 0" class="flex flex-col">
          <div class="py-0">
            <div
              class="text-gray-lp-300 text-12px xxl:text-14px px-2 font-medium bg-gray-lp-200 rounded inline-flex"
            >
              Berita Utama
            </div>
          </div>
          <div class="flex flex-row overflow-auto py-4 w-full">
            <splide
              :options="{
                autoplay: true,
                arrows: false,
                pauseOnHover: true,
                pauseOnFocus: true,
                drag: true,
                focus: 'center',
                rewind: true,
                type: 'loop',
                padding: {
                  right: '5rem',
                  left: 0
                },
                classes: {
                  page:
                    'splide__pagination__page splide__pagination__page.is-active opacity-100 hover:bg-red-lp-100'
                }
              }"
              class="w-full"
              style="min-width: 267px; height: 260px"
            >
              <splide-slide
                v-for="(item, index) in mainNotifications"
                :key="index"
              >
                <div class="flex flex-col h-full mr-4">
                  <div
                    :class="
                      `${!item.read &&
                        'bg-gray-lp-700'} px-4 sm:px-6 py-4 flex flex-col sm:flex-row items-start cursor-pointer w-full h-full shadow-black border border-gray-lp-400 rounded-md m-1`
                    "
                    @click="goDetail(item.id)"
                  >
                    <div class="w-6 mr-2 self-center mb-1 sm:mb-0 sm:self-auto">
                      <div
                        :class="
                          `${
                            initialNameNotification(item.createdBy).color
                          } ${!isCollapse &&
                            'mr-2'} w-6 h-6 font-semibold text-10px items-center tracking-wider flex flex-col justify-center text-white rounded-full`
                        "
                      >
                        {{ initialNameNotification(item.createdBy).initial }}
                      </div>
                    </div>
                    <div class="flex flex-col text-12px xxl:text-14px w-full">
                      <div class="text-gray-lp-300">
                        <span class="font-semibold">{{ item.createdBy }}</span>
                        {{
                          item.createdBy.length + item.title.length > 130
                            ? `${(
                                item.createdBy +
                                " membagikan berita baru : " +
                                item.title
                              ).substr(
                                item.createdBy.length,
                                130 - item.createdBy.length
                              )} . . . . .`
                            : ` membagikan berita baru : ${item.title
                                .charAt(0)
                                .toUpperCase() + item.title.slice(1)}`
                        }}
                      </div>
                      <div class="text-gray-lp-500 whitespace-no-wrap py-1">
                        {{ formatDate(item.releasedAt) }}
                      </div>
                      <img
                        v-show="item.bannerImage"
                        :src="item.bannerImage"
                        class="pt-2 w-300px h-24 object-cover"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </splide-slide>
            </splide>
          </div>
        </div>
        <hr />
        <div class="flex flex-col w-full pb-4">
          <div
            v-for="(e, i) in allNotifications"
            :key="i"
            class="border-b border-gray-lp-700 py-4"
          >
            <div class="px-6 py-2 text-12px text-gray-lp-500">
              {{ i }}
            </div>
            <div v-for="(item, index) in e" :key="index">
              <div
                :class="
                  `${!item.read && 'bg-gray-lp-700'} ${index ===
                    allNotifications.length - 1 &&
                    'rounded-b-md'} cursor-pointer px-6 py-4 flex flex-row items-start`
                "
                @click="goDetail(item.id)"
              >
                <div class="w-6 mr-2">
                  <div
                    :class="
                      `${
                        initialNameNotification(item.createdBy).color
                      } ${!isCollapse &&
                        'mr-2'} w-6 h-6 font-semibold text-10px items-center tracking-wider flex flex-col justify-center text-white rounded-full`
                    "
                  >
                    {{ initialNameNotification(item.createdBy).initial }}
                  </div>
                </div>
                <div class="flex flex-col text-12px w-full">
                  <div class="flex flex-row justify-between">
                    <div class="text-gray-lp-300 text-12px xxl:text-14px mr-6">
                      <span class="font-semibold">{{ item.createdBy }}</span>
                      {{
                        item.createdBy.length + item.title.length > 130
                          ? `${(
                              item.createdBy +
                              " membagikan berita baru : " +
                              item.title
                            ).substr(
                              item.createdBy.length,
                              130 - item.createdBy.length
                            )}...`
                          : ` membagikan berita baru : ${item.title
                              .charAt(0)
                              .toUpperCase() + item.title.slice(1)}`
                      }}
                    </div>
                    <div class="text-gray-lp-500 whitespace-no-wrap">
                      {{ dateToTime(item.releasedAt) }}
                    </div>
                  </div>
                  <img
                    v-show="item.bannerImage"
                    :src="item.bannerImage"
                    class="pt-4 w-500px h-40 object-cover"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <infinite-scroll
            :getApiData="getApiData"
            :isLoading="isLoading"
          ></infinite-scroll>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import { Options, Vue } from "vue-class-component";
import {
  initialName,
  dateToDateString,
  dateToTime
} from "@/app/infrastructures/misc/Utils";
import Pill from "@/app/ui/components/pill/index.vue";
import { NotificationController } from "@/app/ui/controllers/NotificationController";
import InfiniteScroll from "@/app/ui/components/infinite-scroll/index.vue";
import { AccountController } from "../../controllers/AccountController";
import { GTMNotificationEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/notification-events";

@Options({
  components: {
    InfiniteScroll,
    Pill
  }
})
export default class Detail extends Vue {
  get isCollapse() {
    return AccountController.isCollapseSidebar;
  }

  created() {
    this.getCurrentRoute();
    this.getUnreadList();
    this.getApiData();
    this.getMainNotifications();
  }

  // Header
  getCurrentRoute() {
    return this.$route.name;
  }

  //   Search
  searchValue = "";
  _onGetSearch(value) {
    this.searchValue = value;
    NotificationController._getListNotificationSearch({
      page: 1,
      limit: 10,
      status: "",
      search: value,
      isPinned: 0,
      sort: this.filterValue,
      cache: true
    });
  }
  onSearch = debounce(value => {
    this._onGetSearch(value);
  }, 500);

  onClearSearch() {
    this.searchValue = "";
    this._onGetSearch("");
  }

  // Type
  filterValue = "desc";
  filterData = [
    { name: "Paling Baru", value: "desc" },
    { name: "Paling Lama", value: "asc" }
  ];
  filterSelect = false;
  onOpenFilterSelect() {
    this.filterSelect = true;
  }
  onCloseFilterSelect() {
    this.filterSelect = false;
  }
  onSelectFilter(name, value) {
    this.onCloseFilterSelect();
    this.filterValue = value;
    NotificationController._getListNotification({
      page: 1,
      limit: 10,
      status: "",
      search: this.searchValue,
      isPinned: 0,
      sort: value,
      cache: true
    });
  }

  // Get List
  getUnreadList() {
    NotificationController._getUnreadData(true);
  }

  getMainNotifications() {
    NotificationController._getAnnouncementData({
      page: 1,
      limit: 0,
      status: "",
      search: this.searchValue,
      isPinned: 0,
      sort: this.filterValue,
      cache: true
    });
  }

  get mainNotifications() {
    return NotificationController.primaryNotificationData;
  }

  // Tooltip Notification
  isOpenNotification = false;
  notification = {
    height: 0,
    width: 0,
    top: 0,
    left: 0
  };
  initialNameNotification(value) {
    return initialName(value);
  }
  dateToTime(value) {
    return dateToTime(value);
  }
  formatDate(date) {
    return `${dateToDateString(new Date(date))} ${dateToTime(
      new Date(date)
    )} WIB`;
  }
  goDetail(id) {
    NotificationController._onReadNotification(id);
    GTMNotificationEvent("notification_display_detail", {
      notificationId: id
    });
    this.$router.push(`/notifications/${id}`);
  }

  // Get infinite scroll
  limit = 10;
  getApiData() {
    NotificationController._getListNotification({
      page: 1,
      limit: this.limit,
      status: "",
      search: this.searchValue,
      isPinned: 0,
      sort: this.filterValue,
      cache: true
    });
    this.limit += 10;
  }

  get isNotificationsExist() {
    const data = NotificationController.groupAllNotification;
    return !isEmpty(data);
  }

  get allNotifications() {
    return NotificationController.groupAllNotification;
  }

  get isLoading() {
    return NotificationController.isLoading;
  }

  // Handle Error
  get isError() {
    return NotificationController.isError;
  }
  get errorCause() {
    return NotificationController.errorCause;
  }
  onReload() {
    this.getUnreadList();
    this.getApiData();
    this.getMainNotifications();
  }
}
</script>

<style>
.splide__pagination__page {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #e0e0e0;
  border-radius: 50%;
  margin: 3px;
  padding: 0;
  transition: transform 0.2s linear;
  border: none;
}

.splide__pagination__page.is-active {
  transform: scale(1.4);
  background: #b82025;
}

.splide__pagination__page:hover {
  cursor: pointer;
}

.splide__pagination__page:not(.is-overflow) {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #e0e0e0;
  border-radius: 50%;
  margin: 3px;
  padding: 0;
  transition: transform 0.2s linear;
  border: none;
}

.splide__pagination__page.is-active:not(.is-overflow) {
  transform: scale(1.4);
  background: #b82025;
}

.filter_data > div {
  margin-right: 0;
}

.filter_data .pointer-events-none {
  padding-right: 1rem;
}
</style>
