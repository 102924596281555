
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    text: {
      default: "",
      type: String
    }
  }
})
export default class Pill extends Vue {}
