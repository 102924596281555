<template>
  <div v-show="isLoading">Loading...............</div>
  <div id="infinite-scroll"></div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    getApiData: {
      default: Function,
      type: Function
    },
    isLoading: {
      default: false,
      type: Boolean
    }
  }
})
export default class InfiniteScroll extends Vue {
  mounted() {
    this.handleData();
  }
  handleData() {
    const getNewData = () => {
      this.getApiData();
    };
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          getNewData();
        }
      });
    }, {});

    const element = document.querySelector("#infinite-scroll");
    observer.observe(element);
  }
}
</script>
